import Darwin from '../DarwinReactLibrary/js/Darwin.js'

/*
* This is an example class to show you how to build your template for calling Darwin APIs.
* You shouldn't use this class but create your own that will take care of some default parameters
* that are passed into the Darwin API methods.
*/
export default class API {

  //the name of your website
  //this will set the title of your app
  static siteName() {
    return "Darwin Inc."
  }
  //the version of your api
  static version() {
    return 'v1-6'
  }
  //the client_id of this site
  static client_id() {
    return '3-Website-1'
  }
  //the app tag of this site
  static app_tag() {
    let et = API.environment_tag()
    let at = 'darwin'
    if (et.length > 0) {
      return at + '-' + et
    } else {
      return at
    }
  }
  //the environment to use. Leave as '' for the default environment.
  static environment_tag() {
    return ''
    /*if (window.location.hostname === "app.zocha.com") {
      return ''
    } else if (window.location.hostname === "demo.zocha.com") {
      return 'demo'
    } else if (window.location.hostname === "sandbox.zocha.com") {
      return 'sandbox'
    } else if (window.location.hostname === "localhost") {
      return 'sandbox'
    } else {
      return 'sandbox'
    }*/
  }
  //You only need to set this if the page you return to after logging in
  //is different than your homepage.
  //the url to return to once the user has logged in.
  static redirectURL() {
    return window.location.origin;
  }
  //the baseURL of your api
  static baseURL() {
    return 'https://' + API.app_tag() + '.app.darwincloud.com/' + API.app_tag() + '/' + API.version() + '/'
  }
  //the provider to use for authenticating.
  //can be darwin, facebook, google, or password (manual login pages)
  static authProvider() {
    return "password"
  }
  //the style to apply for authenticating
  //can be light or dark
  static authStyle() {
    return "dark"
  }
  //the authURL to redirect to for login
  //To use this url, we must add a state parameter equal to some random string
  //We also create a random string called code_challenge and store it, then sha256PKCE encrypt it,
  //and pass along the encrypted version as a query parameter.
  static authURL(provider = API.authProvider(), authStyle = API.authStyle()) {
    //return API.baseURL() + 'authorize?response_type=code&client_id=' + API.client_id() + "&scope=&style=" + authStyle + "&code_challenge_method=S256&redirect_uri=" + API.redirectURL()
    return "/login?"
  }
  //the refreshURL to call for refreshing tokens
  static refreshURL() {
    return API.baseURL() + 'access_token'
  }
  /*
  * Calls a Darwin API refreshing tokens and showing the login screen as necessary
  * For an example of calling this function
    API.callDarwinAPI("GET", "apiFeature/1", {}, (result) => {
      if ("error" in result) {
        this.setState({
          loading: false
        })
        return
      }
      console.log("result", result.data)
    })
  */
  static callDarwinAPI(method, url, data, callback, headers) {
    let aID = API.getAccessAccountID()
    let hs = {}
    for (let key in headers) {
      hs[key] = headers[key]
    }
    if (aID !== false) {
      hs['x-access-account-id'] = aID
    }
    Darwin.callSecuredAPI(method, API.baseURL() + url, data, hs, callback, API.authURL(), API.authProvider(), API.client_id(), API.refreshURL())
  }

  static getAccessAccountID() {
    return API.accessAccountID || false
  }

  /*
  Calls an unsecured DarwinAPI.
  */
  static callDarwinAPIUnsecured(method, url, data, callback) {
    Darwin.callUnsecuredAPI(method, API.baseURL() + url, data, callback)
  }

  /*
  Uploads a file to S3.
  */
  static uploadFileToS3(formAttributes, formInputs, key, data, callback) {
    Darwin.uploadFileToS3(formAttributes, formInputs, key, data, callback)
  }

  /*
  * Logs the user out of the website and redirects back to the login url.
  */
  static logout() {
    API.accessAccountID = false
    Darwin.logout(API.authURL())
  }
  /*
  Calls this app's API to login the user.
  */
  static manualLogin(email, password, callback) {
    Darwin.manualLogin(API.client_id(), API.baseURL(), email, password, callback)
  }
  /*
  Calls this app's API to login the user with a multi-factor authentication code.
  */
  static manualLoginMFA(email, password, code, callback) {
    Darwin.manualLogin(API.client_id(), API.baseURL(), email, password, callback, code)
  }
  /*
  Calls this app's API to login the user with a multi-factor authentication backup code.
  */
  static manualLoginMFABackupCode(email, password, backupCode, callback) {
    Darwin.manualLogin(API.client_id(), API.baseURL(), email, password, callback, null, backupCode)
  }
  /*
  Calls this app's API to setup 2FA for an Authenticator App.
  Gets back a secret code to use to generate a QR Code for entry in the Authenticator App.
  */
  static setup2FAAuthCode(state, callback) {
    API.callDarwinAPI("POST", "setup2FAAuthCode", {
      state: state,
    }, callback)
  }
  /*
  Calls this app's API to setup 2FA for a phone number.
  Phone number must contain country code.
  */
  static setup2FAPhoneNumber(phoneNumber, state, callback) {
    API.callDarwinAPI("POST", "setup2FAPhoneNumber", {
      phoneNumber: phoneNumber,
      state: state,
    }, callback)
  }
  /*
  Calls this app's API to resend a code to your phone for 2FA
  */
  static setup2FAPhoneNumberResend(state, method, callback) {
    API.callDarwinAPI("POST", "setup2FAPhoneNumberResend", {
      state: state,
      method: method,
    }, callback)
  }
  /*
  Calls this app's API to check a code when setting up 2FA.
  */
  static setup2FACodeCheck(state, verificationCode, callback) {
    API.callDarwinAPI("POST", "setup2FACodeCheck", {
      state: state,
      verificationCode: verificationCode,
    }, callback)
  }
  /*
  Calls this app's API to check a code when setting up 2FA.
  */
  static remove2FA(callback) {
    API.callDarwinAPI("POST", "remove2FA", {
    }, callback)
  }
  /*
  Calls this app's API to regenerate a backup code for 2FA.
  */
  static regenerate2FABackupCode(callback) {
    API.callDarwinAPI("POST", "backup2FA", {
    }, callback)
  }
  /*
  Calls this app's API to pre-verify an email address.
  1) Call this
  2) Call preVerifyEmailCheckCode
  3) Call createPreVerifiedAccount
  */
  static preVerifyEmail(email, state, link, callback) {
    API.callDarwinAPIUnsecured("POST", "createAccountEmail", {
      email: email,
      state: state,
      link: link, //optional callback link
      clientID: API.client_id(),
    }, callback)
  }
  /*
  Calls this app's API to check the code and pre-verify an email address.
  */
  static preVerifyEmailCheckCode(state, verificationCode, callback) {
    API.callDarwinAPIUnsecured("POST", "createAccountEmailVerify", {
      state: state,
      verificationCode: verificationCode,
    }, callback)
  }
  /*
  Calls this app's API to create an account.
  */
  static createPreVerifiedAccount(name, emailState, password, confirmPassword, extraData, callback) {
    API.callDarwinAPIUnsecured("POST", "createAccount", {
      name: name,
      emailState: emailState,
      password: password,
      confirmPassword: confirmPassword,
      extra: extraData,
      clientID: API.client_id(),
    }, callback)
  }
  /*
  Calls this app's API to create an account.
  */
  static manualCreateAccount(name, email, password, confirmPassword, extraData, callback) {
    API.callDarwinAPIUnsecured("POST", "createAccount", {
      name: name,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      extra: extraData,
      clientID: API.client_id(),
    }, callback)
  }
  /*
  Calls this app's API to resend a confirmation email.
  */
  static manualResendConfirmation(email, callback) {
    API.callDarwinAPIUnsecured("POST", "resendVerificationEmail", {
      email: email,
      clientID: API.client_id(),
    }, callback)
  }
  /*
  Calls this app's API to resend the confirmation email by accountID.
  */
  static manualResendConfirmationByID(accountID, callback) {
    API.callDarwinAPIUnsecured("POST", "resendVerificationEmailByID", {
      accountID: accountID,
    }, callback)
  }
  /*
  Calls this app's API to reset your password.
  */
  static manualResetPassword(email, callback) {
    API.callDarwinAPIUnsecured("POST", "resetPassword", {
      email: email,
      clientID: API.client_id()
    }, callback)
  }
  /*
  Calls this app's API to reset your password part 2
  */
  static manualResetPassword2(cryptoKey, callback) {
    API.callDarwinAPIUnsecured("POST", "resetPassword2/" + cryptoKey, {}, callback)
  }
  /*
  Calls this app's API to reset your password part 2
  */
  static manualResetPassword3(cryptoKey, password, confirmPassword, callback) {
    API.callDarwinAPIUnsecured("POST", "resetPassword3/" + cryptoKey, {
      password: password,
      confirmPassword: confirmPassword,
    }, callback)
  }
  /*
  * Check to see if the user is logged in or redirect to the login url.
  */
  static checkAuthentication(callback) {
    Darwin.checkAuthentication(API.authURL(), API.authProvider(), API.refreshURL(), API.client_id(), API.redirectURL(), (authenticated, result) => {
      //remove the query parameters from the browser history
      if (result === "traded") {
        window.history.replaceState({}, API.siteName(), "/");
      }
      callback()
    })
  }

  /*
  Determines if the user has logged in to this site or not.
  */
  static hasLoggedIn() {
    if (API.getCookie("darwin-access") !== "") {
      return true
    }
    return false
  }

  /*
  * Called to refresh the tokens if necessary
  */
  static refreshTokens(callback) {
    Darwin.refreshTokens(callback, API.authURL(), API.client_id(), API.refreshURL(), API.authProvider())
  }

  /*
  * Returns the current Access Token.
  */
  static getAccessToken() {
    return Darwin.getAccessToken()
  }

  /*
  * Returns the current CSRF Token.
  */
  static getCSRFToken() {
    return Darwin.getCSRFToken()
  }

  /*
  * Sets a cookie or uses storage if possible.
  */
  static setCookie(name, value, expiresSeconds = 7776000) {
    return Darwin.setCookie(name, value, expiresSeconds)
  }

  /*
  * Sets a cookie or uses storage if possible.
  */
  static getCookie(name) {
    return Darwin.getCookie(name)
  }

  /*
  * Deletes a cookie if it exists.
  */
  static deleteCookie(name) {
    return Darwin.deleteCookie(name)
  }

  /*
  * Returns all the url params.
  */
  static getAllUrlParams() {
    return Darwin.getAllUrlParams()
  }
}
