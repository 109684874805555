import React from 'react';
import autoBind from 'react-autobind';
//import * as Components from '../Components'
//import $ from 'jquery';

//Data
import Data from '../data/works.json';

//import the Darwin API classes
//import API from '../API.js';
//import * as Helpers from '../Helpers.js';

//import ImageDarwinText from '../images/Darwin/LogoWithText.svg';

export class SitemapView extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this)
  }

  openEmail() {
    document.location.href = "mailto:hello@darwincloud.com"
    //let win = window.open("mailto:hello@darwincloud.com", '_blank');
    //win.focus();
  }

  visitLink(link) {
    window.location.href = link
  }

  render() {
    return (
      <div className="SitemapView">
        <div className="SitemapViewTitle">
          Darwin Inc.
          <br/>
          Site Map
        </div>
        <div className="SitemapViewGroups">
          <div className="SitemapViewGroup">
            <div className="SitemapViewHeader">
              About Darwin Inc.
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "/About")}>
              Company
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "/Contact")}>
              Contact
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "https://www.darwincloud.com/blog")}>
              Blog
            </div>
          </div>
          <div className="SitemapViewGroup">
            <div className="SitemapViewHeader">
              Darwin Programming
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "/App")}>
              Custom Software
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "/Works")}>
              Projects
            </div>
          </div>
          <div className="SitemapViewGroup">
            <div className="SitemapViewHeader">
              Darwin Studios
              <br/>
              Featured Projects
            </div>
            { Data.works.filter(card => card.category === "Featured").map((card, i) =>
              <div key={"Works_" + i} className="SitemapViewLink" onClick={this.visitLink.bind(this, "/Works/" + card.title)}>
                {card.title}
              </div>
            )}
          </div>
          <div className="SitemapViewGroup">
            <div className="SitemapViewHeader">
              Darwin Studios
              <br/>
              Archives
            </div>
            { Data.works.filter(card => card.category === "Archives").map((card, i) =>
              <div key={"Works_" + i} className="SitemapViewLink" onClick={this.visitLink.bind(this, "/Works/" + card.title)}>
                {card.title}
              </div>
            )}
          </div>
          <div className="SitemapViewGroup">
            <div className="SitemapViewHeader">
              Related Sites
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "https://www.darwincloud.com/blog")}>
              Darwin Blog
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "https://account.darwincloud.com")}>
              Darwin Account
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "https://docs.darwincloud.com")}>
              Documentation
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "https://status.darwincloud.com")}>
              Service Status
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "https://forum.darwincloud.com")}>
              Darwin Forum
            </div>
          </div>
          <div className="SitemapViewGroup">
            <div className="SitemapViewHeader">
              Legal
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "/legal/privacy_policy")}>
              Privacy Policy
            </div>
            <div className="SitemapViewLink" onClick={this.visitLink.bind(this, "/legal/terms_of_use")}>
              Terms of Use
            </div>
          </div>
        </div>
      </div>
    )
  }
}
