import React from 'react';
import autoBind from 'react-autobind';
//import * as Components from '../Components'
//import $ from 'jquery';

//import the Darwin API classes
//import API from '../API.js';
//import * as Helpers from '../Helpers.js';

//import ImageDarwinText from '../images/Darwin/LogoWithText.svg';

export class ServicesView extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this)
  }

  render() {
    return (
      <div>
        Services View
      </div>
    )
  }
}
