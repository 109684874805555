import React from 'react';
import autoBind from 'react-autobind';
import * as Components from '../Components'
//import $ from 'jquery';

//import the Darwin API classes
import API from '../API.js';
//import * as Helpers from '../Helpers.js';

//import ImageDarwinText from '../images/Darwin/LogoWithText.svg';
import ImageDarwinBook from '../images/Darwin/Book.png';

export class AppView extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this)

    let data = {
    }

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Main", //could be Main, Success, or Error
    }
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Main":
        requiredFields = ["email"]
        optionals = []
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Main":
          this.submitEmail(data)
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      return {
        data: d,
        forceCheck: false
      }
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  submitEmail(data) {
    if (!data.email || data.email.length === 0) {
      return
    }
    console.log("Submit Email:", data)
    //Submit the email to the API.
    this.setState({
      loading: true
    }, () => {
      //Call the API.
      //callDarwinAPI("POST", "subscribe/1", data, subscribeCallback);
      API.callDarwinAPIUnsecured("POST", "subscribe/1", {
        email: data.email
      }, (result) => {
        if ("error" in result) {
          console.log(result.error)
          this.setState({
            loading: false,
            view: "Error"
          })
          return
        }
        console.log(result)
        this.setState({
          loading: false,
          view: "Success"
        })
      })
    })
  }

  render() {
    return (
      <div className="AppView">
        {/* Top Header */}
        <div className="AppViewTop">
          <div className="AppViewInput">
            { this.state.loading &&
              <Components.LoadingIndicator type="dark" area={72} style={{ margin:"5px auto" }} />
            }
            {/* Main State - show form for submitting email. */}
            { !this.state.loading && this.state.view === "Main" &&
              <span>
                <div className="AppViewText1">
                  Something exciting is coming soon.
                </div>
                <div className="AppViewText2">
                  A new way ... to program everything.
                </div>
                <div className="AppViewText1">
                  Subscribe below to stay in the loop.
                </div>
                <div className="InputDiv">
                  <Components.InputBottomLine className="inputStyleLight" type="text" name="email" placeholder="my@email.com" validation="email" required="false"
                    title=""
                    description=""
                    hideX={true} validateTyping={false}
                    showInputLine={true}
                    value={this.state.data.email ? this.state.data.email.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
              </span>
            }
            {/* Success State - show the success message. */}
            { !this.state.loading && this.state.view === "Success" &&
              <div className="AppViewInputResult">
                You just made the list!
              </div>
            }
            {/* Error State - show an error. */}
            { !this.state.loading && this.state.view === "Error" &&
              <div className="AppViewInputError">
                There was an error. Please try again later.
              </div>
            }
          </div>
        </div>

        {/* Bottom Footer Image */}
        <div className="AppViewBottom">
          <Components.DualImage className="AppViewBottomImage" image={ImageDarwinBook} />
          <div className="AppViewBottomImageCover">
          </div>
        </div>
      </div>
    )
  }
}
