import React from 'react';
import autoBind from 'react-autobind';
//import * as Components from '../Components'
//import $ from 'jquery';

//import the Darwin API classes
//import API from '../API.js';
//import * as Helpers from '../Helpers.js';

import ImageDarwinAbout from '../images/Darwin/AboutImage.png';

export class AboutView extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this)
  }

  openEmail() {
    document.location.href = "mailto:hello@darwincloud.com"
    //let win = window.open("mailto:hello@darwincloud.com", '_blank');
    //win.focus();
  }

  visitLink(link) {
    window.open(link, "_blank")
  }

  render() {
    return (
      <div className="AboutView">
        <img alt="About Darwin" src={ImageDarwinAbout} className="AboutViewImage" />
        <div className="AboutViewTitle">
          Dream
        </div>
        <div className="AboutViewDescription">
          Darwin was once a dream. An eclectic vision of what programming could become. A world in which we are no longer limited by our capabilities, but rather our imagination creating as fast as thought.
        </div>
        <div className="AboutViewDescription">
          As Darwin started to take shape, it became clear that it would take years to bring him to life.
        </div>
        <div className="AboutViewDescription">
          To fund the project, we started Darwin Studios, an app development firm working on a wide array of projects from Financial Technology to Sports Analytics. We used Darwin to build all of these projects, and this has had a huge impact in shaping what Darwin has become.
        </div>
        <div className="AboutViewDescription">
          After years of labor, it is almost ready...
        </div>
        <div className="AboutViewDescription">
          Get ready to <b>reimagine everything</b>.
        </div>
      </div>
    )
  }
}
