import React from 'react';
import autoBind from 'react-autobind';
import * as Components from './resources/Components'
//import $ from 'jquery';

//import the Darwin API classes
//import API from './resources/API.js';
import * as Helpers from './resources/Helpers.js';

import * as Home from './resources/screens/Home.js';
import * as Four0Four from './resources/screens/Four0Four.js';
import * as AppView from './resources/screens/App.js';
import * as Works from './resources/screens/Works.js';
import * as Services from './resources/screens/Services.js';
import * as Contact from './resources/screens/Contact.js';
import * as Legal from './resources/screens/Legal.js';
import * as Sitemap from './resources/screens/Sitemap.js';
import * as About from './resources/screens/About.js';
import * as Geoji from './resources/screens/Geoji.js';


//CSS
import './resources/master.scss';

import Data from './resources/data/works.json';

//Images
import ImageDarwin from './resources/images/Darwin/Darwin.svg';

//import ImageBottomCorner from './resources/images/Darwin/BottomCorner.svg';
import ImageMenuBar from './resources/images/Darwin/MenuBar.svg';
import ImageXButton from './resources/images/Darwin/XButton.svg';

import ImageIconApp from './resources/images/Darwin/IconApp.svg';
import ImageIconAppBlue from './resources/images/Darwin/IconAppBlue.svg';
import ImageIconWorks from './resources/images/Darwin/IconWorks.svg';
import ImageIconWorksBlue from './resources/images/Darwin/IconWorksBlue.svg';
//import ImageIconServices from './resources/images/Darwin/IconServices.svg';
//import ImageIconServicesBlue from './resources/images/Darwin/IconServicesBlue.svg';
import ImageIconContact from './resources/images/Darwin/IconContact.svg';
import ImageIconContactBlue from './resources/images/Darwin/IconContactBlue.svg';
import ImageIconAbout from './resources/images/Darwin/IconAbout.svg';
import ImageIconAboutBlue from './resources/images/Darwin/IconAboutBlue.svg';
import ImageIconBlog from './resources/images/Darwin/IconBlog.svg';

/*
The main entrypoint into the app.
*/
class App extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this);

    this.state = {
      showingOverlayMenu: false,
      view: "Home", //The main view that is showing
      secondView: "", //The second view (subview) - if necessary.
      selectedWorkIndex: 0, //The index of the selected work to show.
    }
  }

  componentDidMount() {
    let shouldCheckAuth = this.updateViewBasedOnPath(true)

    //1) Check to see if we are logged in and if not redirect to the login screen
    if (shouldCheckAuth) {
      //FUTURE: Show the login screen.
    }

    //2) Detect back/forward buttons.
    window.onpopstate = () => {
      //console.log("Popped", window.location.pathname)
      this.updateViewBasedOnPath(false)
    }
  }

  /*
  Updates the current view based on the url path.
  */
  updateViewBasedOnPath(updatePath = true) {
    let pathComponents = Helpers.getWindowPathComponents()
    //console.log(pathComponents, updatePath)
    let shouldCheckAuth = false
    console.log("path: " + pathComponents[0].toLowerCase());
    switch (pathComponents[0].toLowerCase()) {
      case "app":
        this.changeView("App", updatePath)
        break;
      case "works":
        if (pathComponents.length > 1 && pathComponents[1].length > 0) {
          //find the work object
          let works = Data.works
          let indi = 0
          for (let i = 0; i < works.length; i++) {
            if (pathComponents[1] === works[i].title.replace(" ", "")) {
              indi = i
            }
          }
          this.selectWork(indi, updatePath)
        } else {
          this.changeView("Works", updatePath)
        }
        break;
      case "services":
        this.changeView("Services", updatePath)
        break;
      case "contact":
        this.changeView("Contact", updatePath)
        break;
      case "sitemap":
        this.changeView("Sitemap", updatePath)
        break;
      case "about":
        this.changeView("About", updatePath)
        break;
      case "geoji":
        this.changeView("Geoji", updatePath)
        break;
      case "legal":
        let pp1 = pathComponents.length > 0 ? pathComponents[1] : ""
        this.changeView("Legal", updatePath, pp1)
        break;
      case "home":
      case "":
        this.changeView("Home", updatePath)
        break;
      default:
        this.changeView("404", updatePath)
        break;
    }

    return shouldCheckAuth
  }

  /*
  Toggles showing/hiding the overlay menu
  */
  toggleOverlayMenu() {
    this.setState((old) => {
      return {
        showingOverlayMenu: !old.showingOverlayMenu
      }
    })
  }

  /*
  Changes the view
  */
  changeView(newView, updatePath = true, secondView = "") {
    if (newView === "Blog") {
      document.location.href = "https://www.darwincloud.com/blog"
      return
    }
    let sv = secondView
    if (sv === "privacy_policy.pdf") {
      sv = "privacy_policy"
    } else if (sv === "terms_of_use.pdf") {
      sv = "terms_of_use"
    }

    this.setState({
      view: newView,
      showingOverlayMenu: false,
      secondView: sv
    }, () => {
      switch (newView) {
        case "Home":
          document.title = "Darwin - App Development"
          if (updatePath) {
            Helpers.updateWindowPath("/")
          }
          break;
        case "404":
          document.title = "Darwin - 404 Error"
          //TODO: Change status code to 404

          /*if (updatePath) {
            Helpers.updateWindowPath("404")
          }*/
          break;
        case "App":
          document.title = "Darwin - Development Environment"
          if (updatePath) {
            Helpers.updateWindowPath("/App")
          }
          break;
        case "Works":
          document.title = "Darwin - Featured Projects"
          if (updatePath) {
            Helpers.updateWindowPath("/Works/")
          }
          break;
        case "Services":
          document.title = "Darwin - Services"
          if (updatePath) {
            Helpers.updateWindowPath("/Services")
          }
          break;
        case "Contact":
          document.title = "Darwin - Contact"
          if (updatePath) {
            Helpers.updateWindowPath("/Contact")
          }
          break;
        case "About":
          document.title = "Darwin - About"
          if (updatePath) {
            Helpers.updateWindowPath("/About")
          }
          break;
        case "Geoji":
          document.title = "Darwin - Geoji"
          if (updatePath) {
            Helpers.updateWindowPath("/Geoji")
          }
          break;
        case "Sitemap":
          document.title = "Darwin - Site Map"
          if (updatePath) {
            Helpers.updateWindowPath("/Sitemap")
          }
          break;
        case "Legal":
          switch (sv) {
            case "privacy_policy":
              document.title = "Darwin - Privacy Policy"
              if (updatePath) {
                Helpers.updateWindowPath("/Legal/privacy_policy")
              }
              break;
            case "terms_of_use":
              document.title = "Darwin - Terms of Use"
              if (updatePath) {
                Helpers.updateWindowPath("/Legal/terms_of_use")
              }
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    })
  }

  /*
  Selects a work to show the details by index within the works.json file.
  */
  selectWork(indi, updatePath = true) {
    this.setState({
      view: "WorkDetails",
      selectedWorkIndex: indi
    }, () => {
      let title = Data.works[indi].title.replace(" ", "")
      document.title = "Darwin - Project - " + Data.works[indi].title
      if (updatePath) {
        Helpers.updateWindowPath("/Works/" + title)
      }
    })
  }

  nextProject() {
    let newIndi = this.state.selectedWorkIndex
    newIndi += 1
    if (newIndi >= Data.works.length) {
      newIndi = 0
    }
    this.selectWork(newIndi)
  }

  render() {
    let topBarProps = {
      showImages: this.state.view !== "Works",
      toggleOverlayMenu: this.toggleOverlayMenu,
      changeView: this.changeView,
      view: this.state.view
    }
    let viewProps = {
      changeView: this.changeView,
      workIndex: this.state.selectedWorkIndex,
      nextProject: this.nextProject,
      secondView: this.state.secondView,
    }

    return (
      <div className="App">
        <OverlayMenu showing={this.state.showingOverlayMenu} close={this.toggleOverlayMenu} changeView={this.changeView}/>

        {/* Content Views */}
        <div className={"ContentView"}>
          <TopBar {...topBarProps} />
          { this.state.view === "Home" &&
            <Home.HomeView {...viewProps} />
          }
          { this.state.view === "404" &&
            <Four0Four.Four0Four {...viewProps} />
          }
          { this.state.view === "App" &&
            <AppView.AppView {...viewProps} />
          }
          { this.state.view === "Works" &&
            <Works.WorksView {...viewProps} selectWork={this.selectWork} />
          }
          { this.state.view === "WorkDetails" &&
            <Works.WorkDetailsView {...viewProps} />
          }
          { this.state.view === "Services" &&
            <Services.ServicesView {...viewProps} />
          }
          { this.state.view === "Contact" &&
            <Contact.ContactView {...viewProps} />
          }
          { this.state.view === "About" &&
            <About.AboutView {...viewProps} />
          }
          { this.state.view === "Geoji" &&
            <Geoji.GeojiView {...viewProps} />
          }
          { this.state.view === "Legal" &&
            <Legal.LegalView {...viewProps} />
          }
          { this.state.view === "Sitemap" &&
            <Sitemap.SitemapView {...viewProps} />
          }
          {/* Footer */}
          <Footer {...viewProps} />
        </div>
      </div>
    )
  }
}

/*
The overlay that is shown over the whole view for selecting a page.
*/
class OverlayMenu extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this);
  }

  visitLink(link) {
    window.open(link, "_blank")
  }

  render() {
    return (
      <div className={this.props.showing ? "OverlayMenu" : "OverlayMenu OverlayMenuHidden"}>
        <Components.DualImage image={ImageDarwin} className="OverlayMenuLogo" onClick={this.props.changeView.bind(this, "Home")} />
        <hr />

        {/* Items */}
        <div className="TopBarItemGroup">
          <TopBarItem title="App" image={ImageIconApp} showImage={true} onClick={this.props.changeView} />
          <TopBarItem title="Works" image={ImageIconWorks} showImage={true} onClick={this.props.changeView} />
        </div>
        <div className="TopBarItemGroup">
          {/*<TopBarItem title="Services" image={ImageIconServices} showImage={true} onClick={this.props.changeView} />*/}
          <TopBarItem title="About" image={ImageIconAbout} showImage={true} onClick={this.props.changeView} />
          <TopBarItem title="Contact" image={ImageIconContact} showImage={true} onClick={this.props.changeView} />
        </div>
        <div className="TopBarItemGroup">
          <TopBarItem title="Blog" image={ImageIconBlog} showImage={true} onClick={this.props.changeView} />
        </div>

        <div className="OverlayMenuSpacer" />
        {/*
        <div className="OverlayMenuFooter">
          <div className="OverlayMenuFooterGroup">
            <div className="OverlayMenuFooterItem OverlayMenuFooterItemLink" onClick={this.visitLink.bind(this, "https://docs.darwincloud.com")}>
              Documentation
            </div>
            <div className="OverlayMenuFooterItem OverlayMenuFooterItemLink" onClick={this.visitLink.bind(this, "https://forum.darwincloud.com")}>
              Forum
            </div>
            <div className="OverlayMenuFooterItem OverlayMenuFooterItemLink" onClick={this.visitLink.bind(this, "https://status.darwincloud.com")}>
              Service Status
            </div>
            <div className="OverlayMenuFooterItem OverlayMenuFooterItemLink" onClick={this.visitLink.bind(this, "https://account.darwincloud.com")}>
              Darwin Account
            </div>
            <div className="OverlayMenuFooterItem OverlayMenuFooterItemLink" onClick={this.props.changeView.bind(this, "Legal", true, "privacy_policy")}>
              Privacy Policy
            </div>
            <div className="OverlayMenuFooterItem OverlayMenuFooterItemLink" onClick={this.props.changeView.bind(this, "Legal", true, "terms_of_use")}>
              Terms of Use
            </div>
          </div>
          <div className="OverlayMenuFooterItem OverlayMenuFooterItemFirst">
            Copyright © {(new Date()).getFullYear()} Darwin Inc. All rights reserved.
          </div>
        </div>
        */}

        {/* X Button */}
        <Components.DualImage image={ImageXButton} className="OverlayMenuX" onClick={this.props.close} />
      </div>
    )
  }
}

/*
The top bar of the app.
*/
class TopBar extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this);
  }

  render() {
    return (
      <div className="TopBar">
        {/* Darwin Logo */}
        <Components.DualImage image={ImageDarwin} className="TopBarLogo" onClick={this.props.changeView.bind(this, "Home")} />
        {/* Pages */}
        <TopBarItem title="App" image={ImageIconApp} highlightImage={ImageIconAppBlue} showImage={this.props.showImages} onClick={this.props.changeView} highlight={this.props.view === "App"} />
        <TopBarItem title="Works" image={ImageIconWorks} highlightImage={ImageIconWorksBlue} showImage={this.props.showImages} onClick={this.props.changeView} highlight={this.props.view === "Works"} />
        {/*<TopBarItem title="Services" image={ImageIconServices} highlightImage={ImageIconServicesBlue} showImage={this.props.showImages} onClick={this.props.changeView} highlight={this.props.view === "Services"} />*/}
        <TopBarItem title="About" image={ImageIconAbout} highlightImage={ImageIconAboutBlue} showImage={this.props.showImages} onClick={this.props.changeView} highlight={this.props.view === "About"} />
        <TopBarItem title="Contact" image={ImageIconContact} highlightImage={ImageIconContactBlue} showImage={this.props.showImages} onClick={this.props.changeView} highlight={this.props.view === "Contact"} />
        {/* Space */}
        <div className="TopBarSpacing">
        </div>
        {/* Blog */}
        <TopBarItem title="Blog" image={ImageIconBlog} showImage={this.props.showImages} onClick={this.props.changeView} highlight={false}/>
        <Components.DualImage image={ImageMenuBar} className="TopBarMenu" onClick={this.props.toggleOverlayMenu} />
      </div>
    )
  }
}

/*
A menu item inside the top bar.
Pass in prop showImage={true|false} to show or hide the image.
*/
class TopBarItem extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this);
  }

  render() {
    return (
      <div className="TopBarItem" onClick={this.props.onClick.bind(this, this.props.title)}>
        <div className={"TopBarItemTitle" + (this.props.highlight ? " TopBarItemTitleHighlight" : "")}>
          {this.props.title}
        </div>
        { this.props.showImage &&
          <Components.DualImage image={this.props.highlight ? this.props.highlightImage : this.props.image} className="TopBarItemImage" />
        }
      </div>
    )
  }
}

class Footer extends React.Component {

  changeView(view, a = true, b = "") {
    this.props.changeView(view, a, b)
    window.scrollTo(0, 0)
  }

  visitLink(url) {
    let win = window.open(url, '_blank');
    win.focus();
  }

  render() {
    return (
      <div className="Footer">
        <div className="FooterGroupLogo">
          <Components.DualImage image={ImageDarwin}/>
        </div>
        {/* Discover */}
        <div className="FooterGroup">
          <div className="FooterGroupHeader">
            Discover
          </div>
          <div className="FooterGroupLink" onClick={this.changeView.bind(this, "App")}>
            App
          </div>
          <div className="FooterGroupLink" onClick={this.changeView.bind(this, "Works")}>
            Works
          </div>
        </div>
        {/* Company */}
        <div className="FooterGroup">
          <div className="FooterGroupHeader">
            Company
          </div>
          <div className="FooterGroupLink" onClick={this.changeView.bind(this, "About")}>
            About
          </div>
          <div className="FooterGroupLink" onClick={this.visitLink.bind(this, "https://www.darwincloud.com/blog")}>
            Blog
          </div>
          <div className="FooterGroupLink" onClick={this.changeView.bind(this, "Contact")}>
            Contact
          </div>
        </div>
        {/* Sites */}
        <div className="FooterGroup">
          <div className="FooterGroupHeader">
            Sites
          </div>
          <div className="FooterGroupLink" onClick={this.visitLink.bind(this, "https://account.darwincloud.com")}>
            Darwin Account
          </div>
          <div className="FooterGroupLink" onClick={this.visitLink.bind(this, "https://docs.darwincloud.com")}>
            Documentation
          </div>
          <div className="FooterGroupLink" onClick={this.visitLink.bind(this, "https://status.darwincloud.com")}>
            Service Status
          </div>
          <div className="FooterGroupLink" onClick={this.visitLink.bind(this, "https://forum.darwincloud.com")}>
            Darwin Forum
          </div>
        </div>
        {/* Information */}
        <div className="FooterGroup">
          <div className="FooterGroupHeader">
            Information
          </div>
          <div className="FooterGroupLink" onClick={this.changeView.bind(this, "Legal", true, "terms_of_use")}>
            Terms of Use
          </div>
          <div className="FooterGroupLink" onClick={this.changeView.bind(this, "Legal", true, "privacy_policy")}>
            Privacy Policy
          </div>
          <div className="FooterGroupLink" onClick={this.changeView.bind(this, "Sitemap")}>
            Site Map
          </div>
        </div>
      </div>
    )
  }
}

export default App;
