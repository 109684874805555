import React from 'react';
import autoBind from 'react-autobind';
import * as Components from '../Components'
//import $ from 'jquery';

//import the Darwin API classes
//import API from '../API.js';
//import * as Helpers from '../Helpers.js';

import ImageDarwinText from '../images/Darwin/LogoWithText.svg';

export class HomeView extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this);
  }

  render() {
    return (
      <div className="HomeView">
        <Components.DualImage image={ImageDarwinText} className="HomeViewLogo" />
        <div className="HomeViewDescription">
          Evolutionary Programming
        </div>
      </div>
    )
  }
}
