import React from 'react';
import autoBind from 'react-autobind';
//import * as Components from '../Components'
//import $ from 'jquery';

//import the Darwin API classes
//import API from '../API.js';
//import * as Helpers from '../Helpers.js';

//import ImageDarwinText from '../images/Darwin/LogoWithText.svg';

export class ContactView extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this)
  }

  openEmail() {
    document.location.href = "mailto:hello@darwincloud.com"
    //let win = window.open("mailto:hello@darwincloud.com", '_blank');
    //win.focus();
  }

  visitLink(link) {
    window.open(link, "_blank")
  }

  render() {
    return (
      <div className="ContactView">
        <div className="ContactViewTitle">
          GET IN TOUCH
        </div>
        <div className="ContactViewDescription">
          Interested in working together, curious about a quote, inquiring about Darwin's proprietary software, or just want to say hello!
        </div>
        <div className="ContactViewEmail" onClick={this.openEmail}>
          hello@darwincloud.com
        </div>
        {/*<div className="ContactViewDescription">
          Do you have a question or need support? Take a look at our forum. We try to respond same-day.
        </div>
        <div className="ContactViewLink" onClick={this.visitLink.bind(this, "https://forum.darwincloud.com")}>
          forum.darwincloud.com
        </div>*/}
      </div>
    )
  }
}
