import React from 'react';
import autoBind from 'react-autobind';
//import * as Components from '../Components'
//import $ from 'jquery';

//import the Darwin API classes
//import API from '../API.js';
//import * as Helpers from '../Helpers.js';

// import ImageDarwin404 from '../images/Darwin/AboutImage.png';

export class Four0Four extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this)
  }

  openEmail() {
    document.location.href = "mailto:hello@darwincloud.com"
    //let win = window.open("mailto:hello@darwincloud.com", '_blank');
    //win.focus();
  }

  visitLink(link) {
    window.open(link, "_blank")
  }

  render() {
    return (
      <div className="Four0FourView">
        {/* <img alt="404 Error" src={ImageDarwin404} className="Four0FourViewImage" /> */}
        <div className="Four0FourViewTitle">
          We couldn't find what you are looking for.
        </div>
        <div className="Four0FourViewDescription">
          The url you entered is invalid or the page no longer exists.
        </div>
      </div>
    )
  }
}
