import React from 'react';
import autoBind from 'react-autobind';
//import * as Components from '../Components'
//import $ from 'jquery';

//import the Darwin API classes
//import API from '../API.js';
//import * as Helpers from '../Helpers.js';

// import ImageDarwinAbout from '../images/Darwin/AboutImage.png';

export class GeojiView extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this)
  }

  openEmail() {
    document.location.href = "mailto:hello@darwincloud.com"
    //let win = window.open("mailto:hello@darwincloud.com", '_blank');
    //win.focus();
  }

  visitLink(link) {
    window.open(link, "_blank")
  }

  render() {
    return (
      <div className="GeojiView">
        <iframe id="GeojiIFrame" src="https://geoji.com/g/1817?theme=dark&embedded=true" title="geoji checkout" className="GeojiViewIFrame" />
      </div>
    )
  }
}
