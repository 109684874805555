import React from 'react';
import autoBind from 'react-autobind';
import * as Components from '../Components'
import $ from 'jquery';

//import the Darwin API classes
//import API from '../API.js';
//import * as Helpers from '../Helpers.js';

//Data
import Data from '../data/works.json';

import ImageVisitWebsite from '../images/Darwin/VisitWebsite.svg';
import ImageAppStore from '../images/Darwin/AppStore.svg';
import ImageGooglePlay from '../images/Darwin/GooglePlay.svg';

export class WorksView extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this)

    this.state = {
      activeCategory: "Featured",
      activeCardIndex: 0,
    }

    this.cardContainer = React.createRef()
  }

  changeActiveCategory(newCategory) {
    if (newCategory === "Featured") {
      $(this.cardContainer.current).animate({
        scrollLeft: 0
      })
    } else if (newCategory === "Archives") {
      let count = Data.works.filter(card => card.category === "Featured").length
      $(this.cardContainer.current).animate({
        scrollLeft: count * (240 + 34)
      })
    }
  }

  cardsScrolled(e) {
    let left = e.target.scrollLeft
    let indi = Math.round(left / (240 + 34))
    //console.log(e.target.scrollLeft, indi)
    if (this.state.activeCardIndex !== indi) {
      let count = Data.works.filter(card => card.category === "Featured").length
      this.setState({
        activeCardIndex: indi,
        activeCategory: (indi > count - 1) ? "Archives" : "Featured"
      })
    }
  }

  cardsWheeled(e) {
    e.preventDefault()
    let delta = e.deltaX
    if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
      delta = e.deltaY
    }
    this.cardContainer.current.scrollLeft += (1 * delta)
  }

  render() {
    return (
      <div className="WorksView">
        {/* Categories */}
        <div className="WorksViewCategories">
          <div className={"WorksViewCategorySpace" + (this.state.activeCategory === "Featured" ? " WorksViewCategorySpaceActive" : "")} />
          <div className={"WorksViewCategory" + (this.state.activeCategory === "Featured" ? " WorksViewCategoryActive" : "")}
            onClick={this.changeActiveCategory.bind(this, "Featured")}
            >
            Featured
          </div>
          <div className={"WorksViewCategory" + (this.state.activeCategory === "Archives" ? " WorksViewCategoryActive" : "")}
            onClick={this.changeActiveCategory.bind(this, "Archives")}
            >
            Archives
          </div>
          <div className={"WorksViewCategorySpace" + (this.state.activeCategory === "Archives" ? " WorksViewCategorySpaceActive" : "")} />
        </div>

        {/* Cards */}
        <div className="WorksViewCardContainer" ref={this.cardContainer} onScroll={this.cardsScrolled} onWheel={this.cardsWheeled}>
          { Data.works.map((card, i) =>
              <WorksViewCard {...card} key={"card" + i} onClick={this.props.selectWork.bind(this, i)} />
            )
          }
        </div>

        {/* Label */}
        <div className="WorksViewBottom">
          <div className="WorksViewBottomTitle">
            {Data.works[this.state.activeCardIndex].title}
          </div>
          <div className="WorksViewBottomDescription">
            {Data.works[this.state.activeCardIndex].feature1}
            <br/>
            {Data.works[this.state.activeCardIndex].feature2}
          </div>
        </div>
      </div>
    )
  }
}

/*
A card on the Works Page
*/
class WorksViewCard extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this)
  }

  render() {
    return (
      <div className={"WorksViewCard" + (this.props.active ? " WorksViewCardActive" : "")} onClick={this.props.onClick}>
        <Components.DualImage image={"/images/Cards/" + this.props.image} className="WorksViewCardBackground" />
        <div className="WorksViewCardBox" style={{background: this.props.background}}>
          <div className="WorksViewCardBoxLabel" style={{color: this.props.color}}>
            {this.props.label}
          </div>
        </div>
      </div>
    )
  }
}

/*
The Work Details View
*/
export class WorkDetailsView extends React.Component {

  constructor(props) {
    super(props)
    autoBind(this)
  }

  render() {
    return (
      <div className="WorkDetailsView">
        {/* Left Side */}
        <div className={"WorkDetailsViewLeft " + (Data.works[this.props.workIndex].mockup === "none" ? "WorkDetailsViewLeftFull" : "")}>
          <div className="WorkDetailsViewLeftCategory">
            {Data.works[this.props.workIndex].category}
          </div>
          <div className="WorkDetailsViewLeftTitle">
            {Data.works[this.props.workIndex].title}
          </div>
          { Data.works[this.props.workIndex].description.map((description, i) =>
            <div className="WorkDetailsViewLeftDescription" key={"description_" + i}>
              {description}
            </div>
          )}

          { Data.works[this.props.workIndex].actions.length > 0 &&
            <div className="WorkDetailsViewLeftActions">
              { Data.works[this.props.workIndex].actions.map((action, i) =>
                <a href={action[1]} key={"action_" + i} target="_blank" rel="noopener noreferrer">
                  <div className="WorkDetailsViewLeftAction">
                    { action[0] === "Website" &&
                      <Components.DualImage image={ImageVisitWebsite} className="WorkDetailsViewLeftActionImage" />
                    }
                    { action[0] === "iOS" &&
                      <Components.DualImage image={ImageAppStore} className="WorkDetailsViewLeftActionImage" />
                    }
                    { action[0] === "Android" &&
                      <Components.DualImage image={ImageGooglePlay} className="WorkDetailsViewLeftActionImage" />
                    }
                  </div>
                </a>
              )}
            </div>
          }
        </div>

        {/* Right Side */}
        { Data.works[this.props.workIndex].mockup === "none" &&
          <div className="WorkDetailsViewRightNext" onClick={this.props.nextProject} style={{background:Data.works[this.props.workIndex].background, color:Data.works[this.props.workIndex].color}}>
            Next Project
          </div>
        }
        { Data.works[this.props.workIndex].mockup !== "none" &&
          <div className="WorkDetailsViewRight">
            <Components.DualImage image={"/images/Mockups/" + Data.works[this.props.workIndex].mockup} className="WorkDetailsViewRightBackground" />
            <div className="WorkDetailsViewRightNext" onClick={this.props.nextProject} style={{background:Data.works[this.props.workIndex].background, color:Data.works[this.props.workIndex].color}}>
              Next Project
            </div>
          </div>
        }
      </div>
    )
  }
}
