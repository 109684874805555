import React from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Lottie from 'lottie-web';

import PlacesAutocomplete, {
  geocodeByAddress,
  //getLatLng,
} from 'react-places-autocomplete';

import * as Helpers from './Helpers.js';
import Validation from '../DarwinReactLibrary/js/Validation.js';
import API from './API.js';

import ImageZochaWhite from './images/Zocha/ZochaWhite.svg'
import ImagePlusButtonPurple from './images/Icons/PlusButtonPurple.svg'
import ImageCheckmarkLine from './images/Icons/CheckmarkLineWhite.svg'
import ImageX from './images/Icons/X.svg'

//Changes the image on hover to the dual image
//set properties image, hoverimage, ...
export class DualImage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      hovering: false
    }
  }

  //true or false we are hovering
  hover(h) {
    this.setState({
      hovering: h
    })
  }

  render() {

    /*if (this.props.image === false) {
      <img onMouseOver={this.hover.bind(this, true)} onMouseLeave={this.hover.bind(this, false)} {...this.props}/>
    }*/
    let src = this.props.image
    if (this.state.hovering && this.props.hoverimage) {
      src = this.props.hoverimage
    }

    const {className, style, onClick, ...remainingProps} = this.props;

    return (
      <img className={(this.props.hoverimage ? "dualImage " : "dualImageSingle ") + (this.props.className ? this.props.className : "")}
      onMouseOver={this.hover.bind(this, true)} onMouseLeave={this.hover.bind(this, false)} alt="hover over me"
      src={src} {...remainingProps}
      style={this.props.style || {}}
      onClick={this.props.onClick ? this.props.onClick : undefined}
      title={this.props.tooltip}
      />
    );
  }
}

export class MaterialButton extends React.Component {

  render() {

    let bclass = ""
    switch (this.props.type) {
      case "White":
        bclass = "MaterialButtonWhite"
        break;
      case "Red":
        bclass = "MaterialButtonRed"
        break;
      case "LightRed":
        bclass = "MaterialButtonLightRed"
        break;
      case "Green":
        bclass = "MaterialButtonGreen"
        break;
      case "LightGreen":
        bclass = "MaterialButtonLightGreen"
        break;
      case "Purple":
        bclass = "MaterialButtonPurple"
        break;
      case "LightPurple":
        bclass = "MaterialButtonLightPurple"
        break;
      case "TextDark":
        bclass = "MaterialButtonTextDark"
        break;
      case "TextDarkShadow":
        bclass = "MaterialButtonTextDarkShadow"
        break;
      case "TextRed":
        bclass = "MaterialButtonTextRed"
        break;
      case "TextRedShadow":
        bclass = "MaterialButtonTextRedShadow"
        break;
      case "TextPurpleShadow":
        bclass = "MaterialButtonTextPurpleShadow"
        break;
      case "TextGreenShadow":
        bclass = "MaterialButtonTextGreenShadow"
        break;
      case "PayPal":
        bclass = "MaterialButtonPayPal"
        break;
      //ZOCHA DEFINED BUTTONS
      case "Dark":
        bclass = "MaterialButtonDark"
        break;
      case "DarkText":
        bclass = "MaterialButtonDarkText"
        break;
      case "DarkActive":
        bclass = "MaterialButtonDarkActive"
        break;
      case "Light":
        bclass = "MaterialButtonLight"
        break;
      case "LightText":
        bclass = "MaterialButtonLightText"
        break;
      case "Sidebar":
        bclass = "MaterialButtonSidebar"
        break;
      case "SidebarActive":
        bclass = "MaterialButtonSidebarActive"
        break;
      default:
        break;
    }

    let sclass = ""
    switch (this.props.size) {
      case "Full":
        sclass = "MaterialButtonFull"
        break;
      case "Large":
        sclass = "MaterialButtonLarge"
        break;
      case "Small":
        sclass = "MaterialButtonSmall"
        break;
      case "Content":
        sclass = "MaterialButtonContent"
        break;
      case "Tiny":
        sclass = "MaterialButtonTiny"
        break;
      default:
        break;
    }

    return (
      <button type="button" className={"btn MaterialButton " + bclass + " " + sclass + (this.props.shake ? " AnimationShake" : "")}
        style={this.props.style} onClick={this.props.onClick}>{this.props.children}</button>
    )
  }
}

export class InputBottomLine extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      valid: undefined,
      validResponse: undefined,
      failMessage: "",
      address: '',
      active: false,
    }

    this.onActive = this.onActive.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.onDatePickerChange = this.onDatePickerChange.bind(this)
    this.onShopHoursChange = this.onShopHoursChange.bind(this)
    this.onFileSelectorChange = this.onFileSelectorChange.bind(this)
    this.onFileUploadChange = this.onFileUploadChange.bind(this)
    this.updateValidation = this.updateValidation.bind(this)
    this.changeFilter = this.changeFilter.bind(this)
    this.googleAddressChanged = this.googleAddressChanged.bind(this)
    this.googleAddressSelected = this.googleAddressSelected.bind(this)
  }

  componentDidMount() {
    if (this.props.forceCheck) {
      this.updateValidation(this.props.value)
    }
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.forceCheck && this.props.forceCheck) || (prevProps.matchValue !== this.props.matchValue)) {
      this.updateValidation(this.props.value, this.props.forceCheck)
    }
  }

  /*
  * Used to check if the enter key has been pressed, if so then submit the form.
  */
  onKeyPress(e) {
    if (e.keyCode === 13) {
      this.props.onEnter()
    }
  }

  /*
  * Called when the value of a form has changed.
  */
  onChange(e) {
    //validate this input and update the validation state.
    let val = e.target.value ? e.target.value : ""
    //console.log(val)
    this.updateValidation(val)
  }

  /*
  Called when the box is focused
  */
  onActive() {
    this.setState({
      active: true
    })
  }

  /*
  Called when the box is blurred.
  */
  onBlur(e) {
    let val = e.target.value ? e.target.value : ""
    this.updateValidation(val, true)
    this.setState({
      active: false
    })
  }

  onSelectChange(selection) {
    //console.log("selection: ", selection)
    let val = selection.value
    this.updateValidation(val)
  }

  onCheckboxChange(e) {
    let val = (e.target.checked ? "1" : "0")
    this.updateValidation(val)
  }

  onDatePickerChange(date) {
    console.log(date)
    if (date === null) {
      this.updateValidation("")
    } else {
      let newVal =  date.getFullYear() + "-" + Helpers.padNumber(date.getMonth() + 1, 2) + "-" + Helpers.padNumber(date.getDate(), 2)
      this.updateValidation(newVal)
    }
  }

  onShopHoursChange(value, textValue) {
    //console.log("shopHoursValue: ", value)
    //console.log("shopHoursTextValue: ", textValue)
    //1) Validate the shopHours.
    if (this.updateValidation(value)) {
      //2) Update the shopHoursTextValue.
      this.props.onChange(this.props.name + "Text", textValue, true)
    }
  }

  onFileSelectorChange(url) {
    //console.log("file selection changes: ", url)
    this.updateValidation(url)
  }

  onFileUploadChange(file) {
    //console.log("file selection changes: ", file)
    this.updateValidation(file)
  }

  /**
  * Checks the validation for this field.
  * If validateTyping is false, then we don't update the error state unless forceCheck is true.
  */
  updateValidation(val, forceCheck = false) {
    let value = val
    let type = this.props.validation ? this.props.validation : "text"
    let req = this.props.required === "true" ? true : false
    let matchValue = this.props.matchValue ? this.props.matchValue : ""
    let min = this.props.minimum ? this.props.minimum : -1
    let max = this.props.maximum ? this.props.maximum : -1
    if (type === "phoneNumber" || type === "phoneNumber10") {
      value = value.replace(/[^0-9]/g, '').substring(0, 10)
      //console.log("value", value)
    } else if (type === "zip") {
      value = Helpers.parseZip(value)
    } else if (type === "ein") {
      value = value.replace(/[^0-9]/g, '').substring(0, 9)
    } else if (type === "positiveNumber") {
      value = value.toString()
      value = value.replace(/[^0-9]/g, '')
    } else if (type === "money") {
      //get rid of all non digits and non decimals.
      value = value.toString()
      value = value.replace(/[^0-9.]/g, '')
      //make sure there are only 2 digits after the decimal
      let pos = value.indexOf(".")
      if (pos !== -1) {
        value = value.substring(0, pos + 3)
      }
      //remove all leading zeros.
      if (value.length > 1) {
        while (value.indexOf("0") === 0) {
          value = value.substring(1)
        }
      }
      //add a leading zero if needed.
      pos = value.indexOf(".")
      if (pos === 0) {
        value = "0" + value
      }
    } else if (type === "digits") {
      value = value.toString()
      value = value.replace(/[^0-9]/g, '')
      if (max !== -1) {
        value = value.substring(0, max)
      }
    }

    let result = {}
    if (matchValue) {
      result = Validation.validateMatch(value, type, req, matchValue, min, max);
    } else if (this.props.type === "fileUpload") {
      if (val) {
        result = {
          valid: true,
          validResponse: "valid",
          failMessage: ""
        }
      } else {
        result = {
          valid: false,
          validResponse: "empty",
          failMessage: "You must choose a file!"
        }
      }
    } else {
      result = Validation.validate(value, type, req, min, max);
    }

    //check for minDate and maxDate
    if (result.valid && type === "date") {
      //check minDate
      let dateObj = new Date()
      dateObj.setFullYear(value.substring(0, 4))
      dateObj.setMonth(parseInt(value.substring(5,7)) - 1, value.substring(8,10))
      //set time
      if (this.props.minDate) {
        //console.log("minDate", value, dateObj, this.props.minDate, value.substring(8,10))
        if (dateObj <= this.props.minDate) {
          result = {
            valid: false,
            validResponse: "minimum",
            failMessage: this.props.minDateFail || "Must be greater than " + this.props.minDate
          }
        }
      }
      if (this.props.maxDate) {
        //console.log("maxDate", value, this.props.maxDate)
        if (dateObj >= this.props.maxDate) {
          result = {
            valid: false,
            validResponse: "maximum",
            failMessage: this.props.maxDateFail || "Must be less than " + this.props.maxDate
          }
        }
      }
    }

    if (!forceCheck && this.props.validateTyping !== undefined && this.props.validateTyping === false) {
      //don't set a failed message while we are typing
      this.setState({
        valid: true,
        validResponse: "valid",
        failMessage: ""
      })
    } else {
      this.setState({
        valid: result.valid,
        validResponse: result.validResponse,
        failMessage: result.failMessage
      })
    }
    this.props.onChange(this.props.name, value, result.valid)
    return result.valid
  }

  changeFilter() {
    let value = this.props.value
    for (let i = 0; i < this.props.options.length; i = i + 1) {
      if (this.props.options[i].value === value) {
        if (i + 1 < this.props.options.length) {
          value = this.props.options[i + 1].value
        } else {
          value = this.props.options[0].value
        }
        break
      }
    }
    this.props.onChange(this.props.name, value, true)
  }

  /*
  Google Address was changed
  */
  googleAddressChanged(address) {
    //console.log("Changed", address)
    this.updateValidation(address)
  }

  /*
  Google Address was selected
  */
  googleAddressSelected(address) {
    //console.log("Selected", address)
    //this.googleAddressChanged(address)

    geocodeByAddress(address).then((results) => {
      if (this.props.addressSelected !== undefined) {
        this.props.addressSelected(results)
      }
    }).catch((error) => {
      console.error('Google Address Error', error)
    })
  }

  render() {
    let selectVal = null
    let selectIndex = 0
    let options = this.props.options
    if (this.props.type === "selection" || this.props.type === "filter") {
      if (options.length === 0) {
        options = [{
          value: "",
          label: "Loading ..."
        }]
        selectVal = options[0]
      }
      if (this.props.value) {
        for (let i = 0; i < this.props.options.length; i = i + 1) {
          //check to see if this is a group or not.
          if (this.props.options[i].options) {
            //this is a group
            for (let j = 0; j < this.props.options[i].options.length; j = j + 1) {
              if (this.props.value === this.props.options[i].options[j].value) {
                selectVal = this.props.options[i].options[j]
                selectIndex = i
                break
              }
            }
          } else {
            //this is a value
            if (this.props.value === this.props.options[i].value) {
              selectVal = this.props.options[i]
              selectIndex = i
              break
            }
          }
        }
      }
      if (selectVal === null) {
        selectVal = options[0]
      }
    }

    if (this.props.type === "selection" && Helpers.isMobile()) {
      let newOptions = []
      for (let i = 0; i < options.length; i = i + 1) {
        if (options[i].options) {
          for (let j = 0; j < options[i].options.length; j = j + 1) {
            newOptions.push(options[i].options[j])
          }
        } else {
          newOptions.push(options[i])
        }
      }
      options = newOptions
    }

    let value = this.props.value
    let fee = ""
    if (value !== null && value.length > 0) {
      if (this.props.validation === "phoneNumber" || this.props.validation === "phoneNumber10") {
        //parse this into a phone number with (), spaces, and dashes
        value = Helpers.parsePhoneNumber(value.substring(0, 10))
      }
      if (this.props.validation === "ein") {
        value = Helpers.parseEIN(value)
      }
      if (this.props.validation === "zip") {
        value = Helpers.parseZip(value)
      }
      if (this.props.subtype === "ssn4") {
        if (value.length > 0) {
          value = "XXX-XX-" + value
        }
      } else if (this.props.subtype === "ssn") {
        value = Helpers.formatSSN(value)
      }
      if (this.props.validation === "positiveNumber" && this.props.format !== false) {
        //parse this into a readable number
        value = Helpers.formatNumber(value)
      } else if (this.props.validation === "money") {
        //calculate the fee
        fee = this.props.feePercent * parseFloat(value)
        fee = Math.min(this.props.feeMaximum, fee)
        fee = Math.max(this.props.feeMinimum, fee)
        fee = fee.toString()

        //add in commas to the number before the decimal
        let pos = value.indexOf(".")
        //value = "$"
        if (pos === -1) {
          value = "$" + Helpers.formatNumber(value)
        } else {
          value = "$" + Helpers.formatNumber(value.substring(0, pos)) + "." + value.substring(pos + 1)
        }
        //console.log("value", value)

        //format the fee
        let posFee = fee.indexOf(".")
        if (posFee === -1) {
          fee = "$" + Helpers.formatNumberDecimals(fee, 2)
        } else {
          fee = "$" + Helpers.formatNumberDecimals(fee.substring(0, posFee) + "." + fee.substring(posFee + 1, posFee + 3), 2)
        }
        if (this.state.valid === false) {
          fee = ""
        }
      }
    }

    let checkedVal = ((this.props.value === 1 || this.props.value === "1") ? true : false )

    let dateValue = new Date()
    if (this.props.type === "date") {
      if (this.props.value !== null && value.length > 0) {
        //Parse the date value into day, month, year components
        let parts = value.split("-")
        dateValue.setFullYear(parts[0], parts[1] - 1, parts[2]) //set year, month, day
      }
      if (this.props.maxDate !== null && this.props.maxDate !== undefined) {
        if (this.props.maxDate < dateValue) {
          dateValue = this.props.maxDate
        }
      }
      if (this.props.value === null || value.length === 0) {
        dateValue = null
      }
    }

    let inputStyle = {}
    if (this.props.center) {
      inputStyle.textAlign = "center"
    }

    let extraInputProps = {}
    if (this.props.inputType === "number") {
      extraInputProps["step"] = "any"
    }
    if (this.props.type === "date" && Helpers.isMobile()) {
      extraInputProps["min"] = this.props.minDate || undefined
      extraInputProps["max"] = this.props.maxDate || undefined
    }

    if (this.props.type === "filter") {
      return (
        <div className={"inputFilter " + (selectIndex > 0 ? "inputFilterChanged" : "")}>
          <div className="inputFilterBox" onClick={this.changeFilter}>
            { selectVal.label }
          </div>
        </div>
      )
    } else {
      return (
        <div className={"inputBottomLineLightContainer " + (this.state.valid === false ? "inputBottomLineLightContainerInvalid " : (this.state.valid === true ? "inputBottomLineLightContainerValid " : "")) + (this.props.className ? this.props.className : "")}>
          { this.props.title &&
            <div className="inputBottomLineTitle">
              { this.props.required !== "true" &&
                <span>
                  { this.props.title + " (optional)"}
                </span>
              }
              { this.props.required === "true" &&
                <span>
                  { this.props.title }
                </span>
              }
            </div>
          }
          { this.props.description &&
            <div className="inputBottomLineDescription">
              { this.props.description === "_render_" &&
                this.props.descriptionRender()
              }
              { this.props.description !== "_render_" &&
                this.props.description
              }
            </div>
          }
          { this.props.type === "selection" && !Helpers.isMobile() &&
            <div className="inputBottomLineLightFlexBox inputBottomLineLightFlexBoxClear">
              <Select
                tabIndex={this.props.tabIndex || ""}
                name={this.props.name}
                value={selectVal}
                onChange={this.onSelectChange}
                options={options}
                clearable={false}
                className="react-select-container"
                classNamePrefix="react-select"
              />
              { this.props.hideX !== true &&
                <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
              }
            </div>
          }
          { this.props.type === "selection" && Helpers.isMobile() &&
            <div className="inputBottomLineLightFlexBox">
              <select
                tabIndex={this.props.tabIndex || ""}
                name={this.props.name}
                value={this.props.value}
                onChange={this.onChange}
                className="manualSelect">
                { options.map((opt, i) => (
                  <option key={"opt_" + i} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </select>
              { this.props.hideX !== true &&
                <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
              }
              <div>
              </div>
            </div>
          }
          { this.props.type === "checkbox" &&
            <div className={"inputBottomLineCheckbox " + (this.props.theme ? "inputBottomLineCheckbox" + this.props.theme : "")}>
              <label className={checkedVal ? "inputBottomLineCheckboxTrue" : "inputBottomLineCheckboxFalse"}>
                <input type="checkbox"
                  tabIndex={this.props.tabIndex || ""}
                  name={this.props.name}
                  checked={checkedVal}
                  onChange={this.onCheckboxChange}
                />
                { checkedVal &&
                  <DualImage image={ImageCheckmarkLine} />
                }
              </label>
              <span>{this.props.label}</span>
            </div>
          }
          { this.props.type === "shopHours" &&
            <ShopHours
              tabIndex={this.props.tabIndex || ""}
              name={this.props.name}
              value={value}
              onChange={this.onShopHoursChange}
            />
          }
          { this.props.type === "file" &&
            <FileSelectorDiv
              tabIndex={this.props.tabIndex || ""}
              name={this.props.name}
              value={value}
              onChange={this.onFileSelectorChange}
              APIName={this.props.APIName}
              APIUploadName={this.props.APIUploadName}
              showPopup={this.props.showPopup}
              maxSize={this.props.maxSize}
            />
          }
          { this.props.type === "fileUpload" &&
            <FileUploadDiv
              tabIndex={this.props.tabIndex || ""}
              name={this.props.name}
              onChange={this.onFileUploadChange}
              maxSize={this.props.maxSize}
              accept={this.props.accept}
            />
          }
          { this.props.type === "date" && !Helpers.isMobile() &&
            <div className="inputBottomLineLightFlexBox">
              <DatePicker
                dateFormat="M/d/yyyy"
                selected={dateValue}
                onChange={this.onDatePickerChange}
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                minDate={this.props.minDate || undefined}
                maxDate={this.props.maxDate || undefined}
                placeholderText={this.props.placeholder || "7/20/1969"}
                //customInput={<DatePickerButton dateSet={this.props.value !== null && this.props.value.length > 0} showError={this.state.failMessage.length > 0} />}
              />
              { this.props.hideX !== true &&
                <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
              }
            </div>
          }
          { this.props.type === "date" && Helpers.isMobile() &&
            <div className="inputBottomLineMobileDate">
              <input tabIndex={this.props.tabIndex ? this.props.tabIndex : ""} type={this.props.inputType ? this.props.inputType : (this.props.type ? this.props.type : "text")}
                name={this.props.name} className="inputBottomLineLight" placeholder={this.props.placeholder} value={value}
                style={inputStyle} {...extraInputProps}
                onKeyDown={this.onKeyPress} onChange={this.onChange} onBlur={this.onBlur} />
              { this.props.hideX !== true &&
                <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
              }
            </div>
          }
          { (this.props.type === "text" || this.props.type === "password") &&
            <div className="inputBottomLineLightFlexBox">
              { this.props.type === "money" &&
                <div className="moneyInputSign">
                  $
                </div>
              }
              <input tabIndex={this.props.tabIndex ? this.props.tabIndex : ""} type={this.props.inputType ? this.props.inputType : (this.props.type ? this.props.type : "text")}
                name={this.props.name} className="inputBottomLineLight" placeholder={this.props.placeholder} value={value}
                style={inputStyle} {...extraInputProps}
                onKeyDown={this.onKeyPress} onChange={this.onChange} onBlur={this.onBlur} onFocus={this.onActive} />
              { this.props.hideX !== true &&
                <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
              }
            </div>
          }
          { this.props.type === "googleAddress" &&
              <PlacesAutocomplete value={this.props.value} onChange={this.googleAddressChanged} onSelect={this.googleAddressSelected} searchOptions={{
                componentRestrictions: {
                  country: "us"
                },
                types: ['address']
              }}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <div className="inputBottomLineLightFlexBox">
                      <input
                        {...getInputProps({
                          placeholder: this.props.placeholder,
                          className: 'inputBottomLineLight',
                        })}
                      />
                      { this.props.hideX !== true &&
                        <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
                      }
                    </div>
                    <div className="GoogleMapsDropdownContainer">
                      { loading &&
                        <div className="GoogleMapsDropdownContainerLoading">Loading...</div>
                      }
                      { suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'GoogleMapsItemActive'
                          : 'GoogleMapsItem';
                        // inline style for demonstration purpose
                        const style = {}
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
          }
          { this.props.type === "display" &&
            <input readOnly type={this.props.type ? this.props.type : "display"}
              name={this.props.name} className="inputBottomLineLight inputBottomLineLightDisplay" placeholder={this.props.placeholder} value={value}
              style={inputStyle} />
          }
          { this.props.note &&
            <div className="inputBottomLineNote">
              {this.props.note}
            </div>
          }
          { this.props.calculateFee && fee.length > 0 &&
            <div className="inputBottomLineFee">
              Zocha Fee {fee}
            </div>
          }
          { this.props.showInputLine &&
            <div className={"inputBottomLineUnderline " + (this.state.active ? "inputBottomLineUnderlineFull" : "")}>
            </div>
          }
          <div className="inputBottomLineInvalid">
            { this.state.failMessage }
          </div>
          <div style={{clear: "both"}}></div>
          {/* <div className="inputBottomLineBlockLight"></div> */}
        </div>
      )
    }
  }
}

export class ShopHours extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }

    this.parseTimeslots = this.parseTimeslots.bind(this)
    this.padNumber = this.padNumber.bind(this)
    this.onChange = this.onChange.bind(this)
    this.dayToAbbreviation = this.dayToAbbreviation.bind(this)
  }

  //Parses the timeslots out of the days and returns it as an array of days
  parseTimeslots(value) {
    let days = []
    let values = []
    if (value.length > 0) {
      values = value.split(",")
    }
    for (let i = 0; i < 7; i = i + 1) {
      let startTime = i * 24
      let stopTime = (i + 1) * 24

      let foundRange = "0" //0 is closed.
      //find any ranges that cover this timerange
      for (let r = 0; r < values.length; r = r + 1) {
        let times = values[r].split("-")
        let rangeStartTime = parseFloat(times[0].replace(":", "."))
        let rangeStartHour = parseInt(times[0].split(":")[0])
        let rangeStartMinute = parseInt(times[0].split(":")[1])

        let rangeStopTime = parseFloat(times[1].replace(":", "."))
        let rangeStopHour = parseInt(times[1].split(":")[0])
        let rangeStopMinute = parseInt(times[1].split(":")[1])

        let inRange = false
        if (startTime >= rangeStartTime && startTime < rangeStopTime) {
          //console.log("start time of day " + i + " is in range: " + rangeStartTime + " - " + rangeStopTime)
          inRange = true
        } else if (stopTime <= rangeStopTime && stopTime > rangeStartTime) {
          //console.log("stop time of day " + i + " is in range: " + rangeStartTime + " - " + rangeStopTime)
          inRange = true
        } else if (startTime < rangeStartTime && stopTime > rangeStopTime) {
          //console.log("day " + i + " contains range: " + rangeStartTime + " - " + rangeStopTime)
          inRange = true
        }

        if (inRange) {
          //we found the range for this day, now calculate the start and end time on a 0-24 hour schedule
          let stHour = 0
          let stMinute = 0
          let endHour = 24
          let endMinute = 0

          if (rangeStartTime > startTime) {
            stHour = rangeStartHour - (i * 24)
            stMinute = rangeStartMinute
          }
          if (rangeStopTime < stopTime) {
            endHour = rangeStopHour - (i * 24)
            endMinute = rangeStopMinute
          }

          if (stHour === 0 && stMinute === 0 && endHour === 24 && endMinute === 0) {
            //Open All Day
            foundRange = "1"
          } else {
            foundRange = this.padNumber(stHour, 2) + ":" + this.padNumber(stMinute, 2) + "-" + this.padNumber(endHour, 2) + ":" + this.padNumber(endMinute, 2)
          }
          break;
        }
      }
      days.push(foundRange)
    }
    return days
  }

  /*
  Pads a number with leading zeros.
  */
  padNumber(number, digits = 2) {
    let pad = ""
    for (let i = 0; i < digits; i = i + 1) {
      pad += "0"
    }
    let dmax = Math.max(digits, ("" + number).length)
    return (pad + number).slice(-dmax)
  }

  onChange(day, time1, time2) {
    //1) First parse into timeslots.
    let timeslots = this.parseTimeslots(this.props.value)
    //2) Update the day that was edited.
    let newValue = time1 + "-" + time2
    if (time1 === "0" || time1 === "1") {
      newValue = time1
    }
    timeslots[day] = newValue
    //3) Combine the timeslots.
    let combined = ""
    let textGroups = {}
    for (let i = 0; i < timeslots.length; i = i + 1) {
      let addString = ""
      if (timeslots[i] === "0") {
        //nothing to do as this is a closed date.
        if ("0" in textGroups) {
          textGroups["0"].push(i)
        } else {
          textGroups["0"] = [i]
        }
        continue;
      } else if (timeslots[i] === "1") {
        let startHour = 24 * i
        let endHour = 24 * (i + 1)
        addString = this.padNumber(startHour, 2) + ":00-" + this.padNumber(endHour, 2) + ":00"

        if ("1" in textGroups) {
          textGroups["1"].push(i)
        } else {
          textGroups["1"] = [i]
        }
      } else {
        let times = timeslots[i].split("-")
        let rangeStartHour = parseInt(times[0].split(":")[0])
        let rangeStartMinute = parseInt(times[0].split(":")[1])
        let rangeStopHour = parseInt(times[1].split(":")[0])
        let rangeStopMinute = parseInt(times[1].split(":")[1])

        let textRangeStartHour = rangeStartHour % 12
        if (textRangeStartHour === 0) {
          textRangeStartHour = 12
        }
        let textRangeStopHour = rangeStopHour % 12
        if (textRangeStopHour === 0) {
          textRangeStopHour = 12
        }

        let textString = this.padNumber(textRangeStartHour, 1) + ":" + this.padNumber(rangeStartMinute, 2) + "-" + this.padNumber(textRangeStopHour, 1) + ":" + this.padNumber(rangeStopMinute, 2)

        rangeStartHour += 24 * i
        rangeStopHour += 24 * i

        addString = this.padNumber(rangeStartHour, 2) + ":" + this.padNumber(rangeStartMinute, 2) + "-" + this.padNumber(rangeStopHour, 2) + ":" + this.padNumber(rangeStopMinute, 2)

        if (textString in textGroups) {
          textGroups[textString].push(i)
        } else {
          textGroups[textString] = [i]
        }
      }
      if (combined.length > 0) {
        combined += ","
      }
      combined += addString
    }

    //4) Parse the textGroups into a textString
    let textValue = ""
    let available = [0, 1, 2, 3, 4, 5, 6]
    while (available.length > 0) {
      let search = available[0]
      for (let key in textGroups) {
        if (textGroups[key].includes(search)) {
          //found the search.
          let days = textGroups[key]
          let last = -1
          let groupStart = -1
          let groupEnd = -1
          for (let i = 0; i < days.length; i = i + 1) {
            if (groupStart === -1) {
              //new group
              groupStart = days[i]
              groupEnd = days[i]
              last = days[i]
            } else if (days[i] === last + 1) {
              //extend the range
              groupEnd = days[i]
              last = days[i]
            } else {
              //range is over add it to the textValue, then reset.
              if (textValue.length > 0) {
                textValue += ", "
              }
              textValue += this.dayToAbbreviation(groupStart)
              if (groupEnd !== groupStart) {
                textValue += "-" + this.dayToAbbreviation(groupEnd)
              }

              groupStart = days[i]
              groupEnd = days[i]
              last = days[i]
            }
            available = available.filter((value, index, arr) => {
              return value !== days[i]
            })
          }
          //add in the range
          if (textValue.length > 0) {
            textValue += ", "
          }
          textValue += this.dayToAbbreviation(groupStart)
          if (groupEnd !== groupStart) {
            textValue += "-" + this.dayToAbbreviation(groupEnd)
          }
          //add in the text
          if (key === "0") {
            textValue += " Closed"
          } else if (key === "1") {
            textValue += " All Day"
          } else {
            textValue += " " + key
          }
          break
        }
      }
    }
    //5) Push the changes up to the next level.
    this.props.onChange(combined, textValue)
  }

  /*
  The day of the week, 0-6 where 0 is Monday.
  Converts to abbreviations like M, T, W, Th, F, Sa, Su
  */
  dayToAbbreviation(day) {
    switch (day) {
      case 0:
        return "M"
      case 1:
        return "Tu"
      case 2:
        return "W"
      case 3:
        return "Th"
      case 4:
        return "F"
      case 5:
        return "Sa"
      case 6:
        return "Su"
      default:
        return "?"
    }
  }

  render() {
    let timeslots = this.parseTimeslots(this.props.value)
    let hours = [
      {title:"Monday", day:0, value:timeslots[0]},
      {title:"Tuesday", day:1, value:timeslots[1]},
      {title:"Wednesday", day:2, value:timeslots[2]},
      {title:"Thursday", day:3, value:timeslots[3]},
      {title:"Friday", day:4, value:timeslots[4]},
      {title:"Saturday", day:5, value:timeslots[5]},
      {title:"Sunday", day:6, value:timeslots[6]},
    ]
    return (
      <div className="ShopHours">
        {hours.map((hour, i) => (
          <ShopHour key={"ShopHour" + i}
            {...hour}
            onChange={this.onChange}
          />
        ))}
      </div>
    )
  }
}

export class ShopHour extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(selection, action) {
    //get the old props.
    let startTime = this.props.value.split("-")[0]
    let endTime = this.props.value.split("-")[1]

    if (selection.value === "0" || selection.value === "1") {
      //Closed or All Day selected so send this as the value
      startTime = selection.value
      endTime = selection.value
    } else {
      if (action.name === "startTime") {
        startTime = selection.value
      } else {
        endTime = selection.value
      }
    }
    if (startTime === undefined) {
      startTime = "01:00"
    }
    if (endTime === undefined) {
      endTime = "23:00"
    }
    this.props.onChange(this.props.day, startTime, endTime)
  }

  render() {
    let fullOptions = [
      { value:"0", label:"Closed" },
      { value:"1", label:"All Day" },

      { value:"00:00", label:"Midnight" },
      { value:"00:15", label:"12:15 am" },
      { value:"00:30", label:"12:30 am" },
      { value:"00:45", label:"12:45 am" },

      { value:"01:00", label:"01:00 am" },
      { value:"01:15", label:"01:15 am" },
      { value:"01:30", label:"01:30 am" },
      { value:"01:45", label:"01:45 am" },

      { value:"02:00", label:"02:00 am" },
      { value:"02:15", label:"02:15 am" },
      { value:"02:30", label:"02:30 am" },
      { value:"02:45", label:"02:45 am" },

      { value:"03:00", label:"03:00 am" },
      { value:"03:15", label:"03:15 am" },
      { value:"03:30", label:"03:30 am" },
      { value:"03:45", label:"03:45 am" },

      { value:"04:00", label:"04:00 am" },
      { value:"04:15", label:"04:15 am" },
      { value:"04:30", label:"04:30 am" },
      { value:"04:45", label:"04:45 am" },

      { value:"05:00", label:"05:00 am" },
      { value:"05:15", label:"05:15 am" },
      { value:"05:30", label:"05:30 am" },
      { value:"05:45", label:"05:45 am" },

      { value:"06:00", label:"06:00 am" },
      { value:"06:15", label:"06:15 am" },
      { value:"06:30", label:"06:30 am" },
      { value:"06:45", label:"06:45 am" },

      { value:"07:00", label:"07:00 am" },
      { value:"07:15", label:"07:15 am" },
      { value:"07:30", label:"07:30 am" },
      { value:"07:45", label:"07:45 am" },

      { value:"08:00", label:"08:00 am" },
      { value:"08:15", label:"08:15 am" },
      { value:"08:30", label:"08:30 am" },
      { value:"08:45", label:"08:45 am" },

      { value:"09:00", label:"09:00 am" },
      { value:"09:15", label:"09:15 am" },
      { value:"09:30", label:"09:30 am" },
      { value:"09:45", label:"09:45 am" },

      { value:"10:00", label:"10:00 am" },
      { value:"10:15", label:"10:15 am" },
      { value:"10:30", label:"10:30 am" },
      { value:"10:45", label:"10:45 am" },

      { value:"11:00", label:"11:00 am" },
      { value:"11:15", label:"11:15 am" },
      { value:"11:30", label:"11:30 am" },
      { value:"11:45", label:"11:45 am" },

      { value:"12:00", label:"Noon" },
      { value:"12:15", label:"12:15 pm" },
      { value:"12:30", label:"12:30 pm" },
      { value:"12:45", label:"12:45 pm" },

      { value:"13:00", label:"01:00 pm" },
      { value:"13:15", label:"01:15 pm" },
      { value:"13:30", label:"01:30 pm" },
      { value:"13:45", label:"01:45 pm" },

      { value:"14:00", label:"02:00 pm" },
      { value:"14:15", label:"02:15 pm" },
      { value:"14:30", label:"02:30 pm" },
      { value:"14:45", label:"02:45 pm" },

      { value:"15:00", label:"03:00 pm" },
      { value:"15:15", label:"03:15 pm" },
      { value:"15:30", label:"03:30 pm" },
      { value:"15:45", label:"03:45 pm" },

      { value:"16:00", label:"04:00 pm" },
      { value:"16:15", label:"04:15 pm" },
      { value:"16:30", label:"04:30 pm" },
      { value:"16:45", label:"04:45 pm" },

      { value:"17:00", label:"05:00 pm" },
      { value:"17:15", label:"05:15 pm" },
      { value:"17:30", label:"05:30 pm" },
      { value:"17:45", label:"05:45 pm" },

      { value:"18:00", label:"06:00 pm" },
      { value:"18:15", label:"06:15 pm" },
      { value:"18:30", label:"06:30 pm" },
      { value:"18:45", label:"06:45 pm" },

      { value:"19:00", label:"07:00 pm" },
      { value:"19:15", label:"07:15 pm" },
      { value:"19:30", label:"07:30 pm" },
      { value:"19:45", label:"07:45 pm" },

      { value:"20:00", label:"08:00 pm" },
      { value:"20:15", label:"08:15 pm" },
      { value:"20:30", label:"08:30 pm" },
      { value:"20:45", label:"08:45 pm" },

      { value:"21:00", label:"09:00 pm" },
      { value:"21:15", label:"09:15 pm" },
      { value:"21:30", label:"09:30 pm" },
      { value:"21:45", label:"09:45 pm" },

      { value:"22:00", label:"10:00 pm" },
      { value:"22:15", label:"10:15 pm" },
      { value:"22:30", label:"10:30 pm" },
      { value:"22:45", label:"10:45 pm" },

      { value:"23:00", label:"11:00 pm" },
      { value:"23:15", label:"11:15 pm" },
      { value:"23:30", label:"11:30 pm" },
      { value:"23:45", label:"11:45 pm" },

      { value:"24:00", label:"Midnight" },
    ]

    //Find the value in the fullOptions given this.props.value
    let startValue = []
    let endValue = []
    let startOptions = fullOptions
    let endOptions = fullOptions
    let endDisabled = false
    if (this.props.value === "0") {
      startValue = fullOptions[0]
      endValue = fullOptions[0]
      endDisabled = true
    } else if (this.props.value === "1") {
      startValue = fullOptions[1]
      endValue = fullOptions[1]
      endDisabled = true
    } else {
      //we have a time to parse
      let startTime = this.props.value.split("-")[0]
      let endTime = this.props.value.split("-")[1]
      //now find the time
      for (let v = 0; v < fullOptions.length; v = v + 1) {
        if (fullOptions[v].value === startTime) {
          startValue = fullOptions[v]
        }
        if (fullOptions[v].value === endTime) {
          endValue = fullOptions[v]
        }
      }
    }

    return (
      <div className="ShopHour">
        {/* Show the day of the week */}
        <div className="ShopHourDay">{this.props.title}</div>
        {/* Show the first select option */}
        <Select
          name="startTime"
          options={startOptions}
          value={startValue}
          onChange={this.onChange}
          className="react-select-container ShopHourSelect"
          classNamePrefix="react-select"
        />
        {/* Show the dash */}
        <div className="ShopHourDash">–</div>
        {/* Show the second select option */}
        <Select
          name="stopTime"
          options={endOptions}
          value={endValue}
          onChange={this.onChange}
          isDisabled={endDisabled}
          className="react-select-container ShopHourSelect"
          classNamePrefix="react-select"
        />
      </div>
    )
  }
}

export class FileUploadDiv extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      file: false
    }

    this.fileSelected = this.fileSelected.bind(this)

    this.filePicker = React.createRef();
  }

  fileSelected() {
    console.log("file selected")
    if (this.filePicker.current.files.length === 0) {
      //no file selected
      return
    }
    let file = this.filePicker.current.files[0]
    //file has been selected
    //console.log("file selected", file)

    //make sure th file is less than maxSize
    if (file.size > this.props.maxSize) {
      //unset the file
      this.filePicker.current.value = ""
      this.setState({
        file: false
      }, () => {
        this.props.onChange(false)
      })
      return
    }
    this.setState({
      file: file
    }, () => {
      this.props.onChange(file)
    })
    return
  }

  render() {

    let name = "Select a File"
    if (this.state.file) {
      name = this.state.file.name
      if (name.length > 23) {
        name = name.substring(0, 20) + "..."
      }
    }

    return (
      <div className="FileSelectorDiv">
        <input id={"FileSelectorInput" + this.props.name} className="FileSelectorUploadInput" type="file" ref={this.filePicker} onChange={this.fileSelected} accept={this.props.accept || "image/png, image/jpeg, image/jpg, application/pdf"} />
        <MaterialButton type="Light" size="Full" style={{padding:"0px"}}>
          <label htmlFor={"FileSelectorInput" + this.props.name} className="FileSelectorUploadInputLabel">
            {name}
          </label>
        </MaterialButton>
      </div>
    )
  }
}

export class FileSelectorDiv extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      overlay: false,
      imgError: false,
    }

    this.goToSelectFile = this.goToSelectFile.bind(this)
    this.selectedFile = this.selectedFile.bind(this)
    this.imageLoadError = this.imageLoadError.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        imgError: false
      })
    }
  }

  goToSelectFile() {
    this.setState({
      overlay: true
    })
  }

  selectedFile(file) {
    this.setState({
      overlay: false
    })
    if (file.length === 0) {
      //we didn't select anything
      return
    }
    //set this as the newly selected file
    this.props.onChange(file)
  }

  imageLoadError() {
    //console.log("image load error")
    this.setState({
      imgError: true
    })
  }

  render() {

    let value = this.props.value
    if (value === null || value.length === 0 || this.state.imgError) {
      value = ImageZochaWhite
    }

    let fullScreenFileSelectorProps = {
      finished: this.selectedFile,
      APIName: this.props.APIName,
      APIUploadName: this.props.APIUploadName,
      showPopup: this.props.showPopup,
      maxSize: this.props.maxSize,
    }

    return (
      <div className="FileSelectorDiv">
        <img className="FileSelectorDivImage" src={value} onError={this.imageLoadError} alt="Selected Logo" />
        <MaterialButton type="TextPurpleShadow" onClick={this.goToSelectFile}>Select File</MaterialButton>
        { this.state.overlay &&
          <FullScreenFileSelector {...fullScreenFileSelectorProps} />
        }
      </div>
    )
  }
}

export class FullScreenFileSelector extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      files: [],
      uploadingFile: false,
    }

    this.loadFiles = this.loadFiles.bind(this)
    this.cancel = this.cancel.bind(this)
    this.fileSelected = this.fileSelected.bind(this)

    this.filePicker = React.createRef();
  }

  componentDidMount() {
    //Load from the API
    this.loadFiles()
  }

  loadFiles() {
    this.setState({
      loading: true
    }, () => {
      API.callDarwinAPI("GET", this.props.APIName, {}, (result) => {
        if ("error" in result) {
          this.props.showPopup("Error", "Couldn't load your files", result.error)
          return
        }
        this.setState({
          files: result.data,
          loading: false
        })
      })
    })
  }

  cancel() {
    this.props.finished("")
  }

  fileSelected() {
    if (this.filePicker.current.files.length === 0) {
      //no file selected
      return
    }
    let file = this.filePicker.current.files[0]

    this.setState({
      uploadingFile: true
    }, () => {
      //1) Read the File
      let reader = new FileReader();
      reader.onload = (e) => {
        let img = document.createElement("img");
        img.onload = () => {
          //2) Wait for the file to load then resize the image
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          let MAX_WIDTH = this.props.maxSize;
          let MAX_HEIGHT = this.props.maxSize;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          //3) Convert the image to a blob
          canvas.toBlob((blob) => {

            //4) Call the API to retrieve signed post parameters
            API.callDarwinAPI("POST", this.props.APIUploadName, {}, (result) => {
              if ("error" in result) {
                this.props.showPopup("Error", "Couldn't load your files", result.error)
                return
              }
              //5) Upload the file.
              let formInputs = result.data.formInputs
              formInputs["Content-Type"] = "image/png"
              formInputs["Cache-Control"] = "max-age=2592000"
              API.uploadFileToS3(result.data.formAttributes, result.data.formInputs, result.data.filePath, blob, (result) => {
                //console.log("file upload result", result)
                if (typeof result !== 'object' || "error" in result) {
                  //5) If error, show a popup to the user and stop loading.
                  this.setState({
                    uploadingFile: false
                  })
                  this.props.showPopup("Error", "Couldn't upload your file", result)
                  return
                } else {
                  //6) Cancel, go back, and reload the images.
                  this.setState({
                    uploadingFile: false
                  }, () => {
                    this.loadFiles()
                  })
                }
              })
            })
          }, "image/png")
        }
        img.src = e.target.result;
      }
      reader.readAsDataURL(file);
    })
  }

  render() {
    return (
      <div className="FullScreenFileSelector">
        { this.state.uploadingFile &&
          <div className="FullScreenFileSelectorFileLoading">
            <LoadingIndicator />
          </div>
        }
        { !this.state.uploadingFile &&
          <span>
            <div className="FullScreenFileSelectorTitle">File Selector</div>
            <div className="FullScreenFileSelectorDescription">Select an image or upload a new image using the plus button below.</div>
            <div className="FullScreenFileSelectorFileTypes">We accept png and jpg/jpeg files.</div>
            <div className="ButtonDiv">
              <MaterialButton type="TextRedShadow" size="Large" onClick={this.cancel}>Cancel</MaterialButton>
            </div>
            <div className="FullScreenFileSelectorContainer">
              { this.state.loading &&
                <div className="FullScreenFileSelectorFileLoading">
                  <LoadingIndicator top="68px" />
                </div>
              }
              { !this.state.loading &&
                <div className="FullScreenFileSelectorFiles">
                  <div className="FullScreenFileSelectorFileUpload">
                    <input id="FullScreenFile" className="FullScreenFileUploaderInput" type="file" ref={this.filePicker} onChange={this.fileSelected} accept="image/png, image/jpeg" />
                    <label htmlFor="FullScreenFile">
                      <img src={ImagePlusButtonPurple} alt="Upload" />
                    </label>
                  </div>
                  { !this.state.loading && this.state.files.map((file, i) => (
                    <div key={"file" + i} className="FullScreenFileSelectorFile" onClick={() => {this.props.finished(file.url)}}>
                      <img src={file.url} alt={"Icon " + i} />
                    </div>
                  ))}
                </div>
              }
            </div>
          </span>
        }
      </div>
    )
  }
}

// The loading indicator
// set the prop type to light, dark, or rocket
// set the prop area for the width and height
// set the prop separation for top and bottom margin
// OPTIONAL set the prop bc for the background
export class LoadingIndicator extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      error: false
    }
    this.svgcontainer = React.createRef()
  }

  componentDidMount() {
    let path = ""
    switch (this.props.type) {
      case "dark":
        path = "/images/animations/HexAnimation/DarwinLoadingBlack.json"
        break;
      case "light":
        path = "/images/animations/HexAnimation/DarwinLoadingWhite.json"
        break;
      case "rocket":
        path = "/images/animations/Rocketship/Rocketship.json"
        break;
      default:
        this.setState({
          error: true
        })
        break;
    }
    Lottie.loadAnimation({
      container: this.svgcontainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: path,
    })
  }

  render() {
    let loadStyle = this.props.style
    if (loadStyle === undefined) {
      loadStyle = {}
    }
    if (this.props.area !== undefined) {
      loadStyle.width = this.props.area + "px"
      loadStyle.height = this.props.area + "px"
      //loadStyle.borderRadius = (this.props.area / 2) + "px"
    }
    if (this.props.separation !== undefined) {
      loadStyle.margin = this.props.separation + "px auto"
    }
    if (this.props.bc !== undefined) {
      loadStyle.background = this.props.bc;
      //loadStyle.borderRadius = (this.props.area / 2) + "px";
      loadStyle.overflow = "hidden";
    }
    if (this.props.br !== undefined) {
      loadStyle.borderRadius = this.props.br
    }

    if (this.state.error) {
      return (
        <div className="darwinLoadingImage" style={loadStyle} onClick={this.props.onClick}>
          Loading animation error. Unsupported animation &apos;type&apos; prop.
        </div>
      );
    } else {
      return (
        <div className="darwinLoadingImage" style={loadStyle} onClick={this.props.onClick}>
          <div ref={this.svgcontainer} id='svgcontainer'></div>
        </div>
      )
    }
  }
}
